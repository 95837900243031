(function global() {
  'use strict';

  // define namespace's
  var website = website || {};
  var util =  util || {};


  util.hasClass = function(el, className) {
    if (el.classList)
      return el.classList.contains(className)
    else
      return !!el.className.match(new RegExp('(\\s|^)' + className + '(\\s|$)'))
  };

  util.addClass = function(el, className) {
    if (el.classList)
      el.classList.add(className)
    else if (!hasClass(el, className)) el.className += " " + className
  };

  util.removeClass = function(el, className) {
    if (el.classList)
      el.classList.remove(className)
    else if (hasClass(el, className)) {
      var reg = new RegExp('(\\s|^)' + className + '(\\s|$)')
      el.className=el.className.replace(reg, ' ')
    }
  };

  website.lgSwitcher = function () {
    if (document.querySelectorAll(".language-switcher").length) {
      var $lgSwitcher = document.querySelectorAll(".language-switcher")[0],
        $span = $lgSwitcher.querySelectorAll("span")[0];

      // add event on span (open/close)
      $span.addEventListener("click", function (e) {
        e.preventDefault();
        e.stopPropagation();
        $lgSwitcher.classList.toggle('open');
      });

      // add event on document (close)
      document.addEventListener("click", function (e) {
        $lgSwitcher.classList.remove('open');
      });
    };
  };

  website.tooltip = function () {
    // add event for mobile device (touch)
    // add event on each element (open)
    for (var i = 0; i < document.querySelectorAll('[data-tooltip]').length; i++) {
      document.querySelectorAll('[data-tooltip]')[i].addEventListener("touchstart", function () {
        this.classList.add("hover");
      });
    }
    // add event on document (close)
    document.addEventListener("touchstart", function () {
      for (var i = 0; i < document.querySelectorAll('[data-tooltip]').length; i++) {
        document.querySelectorAll("[data-tooltip]")[i].classList.remove("hover");
      }
    });
  };

  website.toggleList = function () {
    var $target = document.querySelectorAll('.toggleList .question');
    for (var i = 0; i < $target.length; i++) {
      $target[i].addEventListener("click", function () {
        var $parent = this.parentNode.parentNode.querySelectorAll('li');
        for (var i = 0; i < $parent.length; i++) {
          if ($parent[i] === this.parentNode) {
            $parent[i].classList.toggle("open");
          } else {
            $parent[i].classList.remove("open");
          }
        }
      });
    }
  };

  website.collapse = function () {
    var $target = document.querySelectorAll('.collapse-item > .collapse-header');
    for (var i = 0; i < $target.length; i++) {
      $target[i].addEventListener("click", function () {
        var $parent = this.parentNode;
        $parent.classList.toggle("open");
        /*
        for (var i = 0; i < $parent.length; i++) {
          if ($parent[i] === this.parentNode) {
            $parent[i].classList.toggle("open");
          } else {
            $parent[i].classList.remove("open");
          }
        }
        */
      });
    }
  };

  website.chatbox = function () {
    var $target = document.querySelectorAll('.chat-btn');
    for (var i = 0; i < $target.length; i++) {
      $target[i].addEventListener("click", function () {
        var $parent = this.parentNode;
        $parent.classList.toggle("open");
      });
    }
  };


  website.responsiveTable = function () {
    // wrapp all table.responsive with div.js-responsive-table
    var $table = document.querySelectorAll('table.responsive');
    for (var i = 0; i < $table.length; i++) {
      var newtable = document.createElement("div");
      newtable.innerHTML = $table[i].outerHTML;
      newtable.setAttribute('class', 'js-responsive-table');
      $table[i].parentNode.insertBefore(newtable, $table[i]);
      $table[i].remove();
    }
  };

  website.datepicker = {
    init : function () {
      var datepicker = document.querySelectorAll('.datepicker');
      if (datepicker.length) {
        for (var i = 0; i < datepicker.length; i++) {
          datepicker[i].querySelector('button').addEventListener("click", function (e) {
            e.preventDefault();
            if (this.closest('.datepicker').classList.contains('show')) {
              this.closest('.datepicker').classList.remove('show');
            }else{
              for (var y = 0; y < datepicker.length; y++) {
                datepicker[y].classList.remove('show');
              }
              this.closest('.datepicker').classList.add('show');
            }

          });
        }
        website.datepicker.outside();
        website.datepicker.closeBt();
      }
    },
    outside : function () {
      document.addEventListener('click', function (event) {
        var target = event.target;
        var datepicker = document.querySelectorAll('.datepicker');
        if ( !target.closest('.datepicker') ) {
          for (var i = 0; i < datepicker.length; i++) {
            datepicker[i].classList.remove('show');
          }
        }
      }, false);
    },
    closeBt : function() {
      var calWrapper = document.querySelectorAll('.calendar-wrapper');
      var buttonClose = document.createElement("button");
      buttonClose.classList.add('close');
      buttonClose.addEventListener("click", function (e) {
        buttonClose.closest('.datepicker').classList.remove('show');
      });
      for (var i = 0; i < calWrapper.length; i++) {
        calWrapper[i].appendChild(buttonClose);
      }
    }
};

  website.modal = function() {
    var $target = document.querySelectorAll('[data-modal]');
    for (var i = 0; i < $target.length; i++) {
      $target[i].addEventListener("click", function (e) {
        e.preventDefault();
        var modal = document.getElementById(e.target.closest("[data-modal]").dataset.modal);
        modal.classList.toggle('open');
      });
    }

    var $modal = document.querySelectorAll('.modal .js-close');
    for (var i = 0; i < $modal.length; i++) {
      $modal[i].addEventListener("click", function (e) {
        e.preventDefault();
        var modal = e.target.closest(".modal");
        modal.classList.remove('open');
      });
    }
  };

  website.openable = function() {
    var $target = document.querySelectorAll('.collapsible');
    for (var i = 0; i < $target.length; i++) {
      $target[i].querySelectorAll('.collapse')[0].addEventListener("click", function (e) {
        e.preventDefault();
        e.target.closest(".collapsible").classList.toggle('open');
      });
    }
  };



  website.init = function () {
    website.lgSwitcher();
    website.toggleList();
    website.collapse();
    website.tooltip();
    website.responsiveTable();
    website.modal();
    website.openable();
    website.chatbox();
    website.datepicker.init();
  };

  document.addEventListener("DOMContentLoaded", function () {
    // initialize app
    website.init();
  });

}());
